/* eslint-disable */
import { $http } from '@/plugins/axios';
import { AxiosResponse } from 'axios';
import groupClassReportDetail from '@/views/groupClassReportDetail/GroupClassReportDetail.vue';

export const monthlyReportService = {
	monthlyReportsList: (params?: any): Promise<AxiosResponse> => {
		return $http.get(`/v1/staff/monthly_reports`, { params });
	},

	monthlyReportsListGroupClass: (params?: any): Promise<AxiosResponse> => {
		return $http.get(`/v1/staff/group_monthly_reports`, { params });
	},

	unCompletedList: (id: string, month_start: any): Promise<AxiosResponse> => {
		return $http.get(
			`v1/staff/students/${id}/monthly_reports/list_incompleted_dates`,
			{ params: { month_start } }
		);
	},

	unCompletedListGroupClass: (
		id: string,
		month_start: any
	): Promise<AxiosResponse> => {
		return $http.get(
			`v1/staff/group_classes/${id}/group_lesson_reports/list_draft_group_lesson_reports`,
			{ params: { month_start } }
		);
	},

	monthlyReport: (
		student_id: string,
		month_start: string,
		oldPayload?: any
	): Promise<AxiosResponse> => {
		return $http.get(
			`v1/staff/students/${student_id}/monthly_reports?month_start=${month_start}`,
			oldPayload
				? {
					headers: {
						common: oldPayload,
					},
				}
				: {}
		);
	},

	monthlyReportOldReport: (
		studentId: string,
		oldData: any,
		params?: any
	): Promise<AxiosResponse> => {
		return $http.get(
			`v1/staff/students/${studentId}/list_monthly_reports`,
			{
				params,
				headers: {
					common: oldData,
				},
			}
		);
	},

	monthlyReportPlan: (
		id: string,
		student_id: string
	): Promise<AxiosResponse> => {
		return $http.get(
			`v1/staff/students/${student_id}/monthly_reports/${id}/plan`
		);
	},

	planHistory(id: string, oldPayload?: any) {
		return $http.get(
			`v1/staff/students/${id}/plans`,
			oldPayload
				? {
					headers: {
						common: oldPayload,
					},
				}
				: {}
		);
	},

	planSchedule(id: string, oldPayload?: any) {
		return $http.get(
			`v1/staff/students/${id}/plans/scheduled`,
			oldPayload
				? {
					headers: {
						common: oldPayload,
					},
				}
				: {}
		);
	},

	planActive(id: string, oldPayload?: any) {
		return $http.get(
			`v1/staff/students/${id}/plans/active`,
			oldPayload
				? {
					headers: {
						common: oldPayload,
					},
				}
				: {}
		);
	},

	planAll(id: string, oldPayload?: any) {
		return $http.get(
			`v1/staff/students/${id}/goals/all`,
			oldPayload
				? {
					headers: {
						common: oldPayload,
					},
				}
				: {}
		);
	},

	submitReport(id: string, student_id: string, payload: any) {
		return $http.post(
			`v1/staff/students/${student_id}/monthly_reports/${id}/submit`,
			payload
		);
	},

	updateNoteReport(id: string, student_id: string, payload: any) {
		return $http.put(
			`v1/staff/students/${student_id}/monthly_reports/${id}`,
			payload
		);
	},

	monthlyReportPublic: (query: string): Promise<AxiosResponse> => {
		return $http.get(`/v1/monthly_reports/show_by_token${query}`);
	},

	getToken(id: string, student_id: string) {
		return $http.post(
			`/v1/staff/students/${student_id}/monthly_reports/${id}/share`
		);
	},

	createMonthlyReport: (
		student_id: string,
		payload: any
	): Promise<AxiosResponse> => {
		return $http.post(`v1/staff/students/${student_id}/monthly_reports`, {
			monthly_report: payload,
		});
	},

	assessmentReports: (
		student_id: string | number,
		monthly_report_id: string | number,
		goal_id: string | number
	): Promise<AxiosResponse> => {
		return $http.get(
			`/v1/staff/students/${student_id}/monthly_reports/${monthly_report_id}/assessment_reports?goal_id=${goal_id}`
		);
	},

	assessmentReportsWithAll: (
		student_id: string | number,
		goal_id: string | number
	): Promise<AxiosResponse> => {
		return $http.get(
			`/v1/staff/students/${student_id}/goals/${goal_id}/detail_with_assessment`
		);
	},

	createAssessmentReports: (
		student_id: string | number,
		monthly_report_id: string | number,
		goal_id: string | number,
		payload: any
	): Promise<AxiosResponse> => {
		return $http.post(
			`/v1/staff/students/${student_id}/monthly_reports/${monthly_report_id}/assessment_reports?goal_id=${goal_id}`,
			payload
		);
	},

	applyNow: (
		student_id: string | number,
		plan_id: string | number
	): Promise<AxiosResponse> => {
		return $http.post(
			`/v1/staff/students/${student_id}/plans/${plan_id}/apply_now`
		);
	},


	updateAssessmentReports: (
		student_id: string | number,
		monthly_report_id: string | number,
		assigment_id: string | number,
		payload: any
	): Promise<AxiosResponse> => {
		return $http.put(
			`/v1/staff/students/${student_id}/monthly_reports/${monthly_report_id}/assessment_reports/${assigment_id}`,
			payload
		);
	},

	planDetail: (
		student_id: string,
		plan_id: string
	): Promise<AxiosResponse> => {
		return $http.get(`v1/staff/students/${student_id}/plans/${plan_id}`);
	},

	sendNotification: (
		student_id: string,
		plan_id: string
	): Promise<AxiosResponse> => {
		return $http.post(
			`v1/staff/students/${student_id}/plans/${plan_id}/send`
		);
	},

	sendMonthlyReport: (
		student_id: string,
		report_id: string
	): Promise<AxiosResponse> => {
		return $http.post(
			`v1/staff/students/${student_id}/monthly_reports/${report_id}/send`
		);
	},

	sendMonthlyReportGroupClass: (
		student_id: string,
		report_id: string
	): Promise<AxiosResponse> => {
		return $http.post(
			`v1/staff/group_classes/${student_id}/group_monthly_reports/${report_id}/send`
		);
	},

	createMonthlyReportGroupClass: (
		group_class_id: string,
		param: any
	): Promise<AxiosResponse> => {
		return $http.post(
			`v1/staff/group_classes/${group_class_id}/group_monthly_reports`,
			param
		);
	},

	detailMonthlyReportGroupClass: (
		group_class_id: string,
		report_id: any
	): Promise<AxiosResponse> => {
		return $http.get(
			`v1/staff/group_classes/${group_class_id}/group_monthly_reports/${report_id}`
		);
	},

	fetchGroupGoalsList: (
		group_class_id: string,
		monthly_report_id: string
	): Promise<AxiosResponse> => {
		return $http.get(
			`v1/staff/group_classes/${group_class_id}/group_monthly_reports/${monthly_report_id}/active_group_plan`
		);
	},

	fetchGroupGoalDetail: (
		group_class_id: string,
		monthly_report_id: string,
		group_goal_id: string
	): Promise<AxiosResponse> => {
		return $http.get(
			`v1/staff/group_classes/${group_class_id}/group_monthly_reports/${monthly_report_id}/student_assessment_group_monthly_reports?group_goal_id=${group_goal_id}`
		);
	},

	createAssessmentReportsGroup: (
		group_class_id: string,
		monthly_report_id: string,
		params: any
	): Promise<AxiosResponse> => {
		return $http.post(
			`v1/staff/group_classes/${group_class_id}/group_monthly_reports/${monthly_report_id}/student_assessment_group_monthly_reports`,
			params
		);
	},

	updateAssessmentReportsGroup: (
		group_class_id: string,
		monthly_report_id: string,
		assessment_id: string,
		params: any
	): Promise<AxiosResponse> => {
		return $http.put(
			`v1/staff/group_classes/${group_class_id}/group_monthly_reports/${monthly_report_id}/student_assessment_group_monthly_reports/${assessment_id}`,
			params
		);
	},

	createAssessmentStep2ReportsGroup: (
		group_class_id: string,
		monthly_report_id: string,
		params: any
	): Promise<AxiosResponse> => {
		return $http.post(
			`v1/staff/group_classes/${group_class_id}/group_monthly_reports/${monthly_report_id}/assessment_group_monthly_reports/`,
			params
		);
	},

	updateAssessmentStep2ReportsGroup: (
		group_class_id: string,
		monthly_report_id: string,
		assessment_id: string,
		params: any
	): Promise<AxiosResponse> => {
		return $http.put(
			`v1/staff/group_classes/${group_class_id}/group_monthly_reports/${monthly_report_id}/assessment_group_monthly_reports/${assessment_id}`,
			params
		);
	},

	submitReportGroupClass: (
		group_class_id: string,
		monthly_report_id: string,
		params: any
	): Promise<AxiosResponse> => {
		return $http.post(
			`v1/staff/group_classes/${group_class_id}/group_monthly_reports/${monthly_report_id}/submit`,
			params
		);
	},

	updateReportGroupClass: (
		group_class_id: string,
		params: any
	): Promise<AxiosResponse> => {
		return $http.post(
			`v1/staff/group_classes/${group_class_id}/group_monthly_reports`,
			params
		);
	},

	editReportGroupClass: (
		group_class_id: string,
		report_id: string,
		params: any
	): Promise<AxiosResponse> => {
		return $http.put(
			`v1/staff/group_classes/${group_class_id}/group_monthly_reports/${report_id}`,
			params
		);
	},

	fetchNoteForStudentGroup: (
		group_class_id: string,
		monthly_report_id: string,
		student_id: string
	): Promise<AxiosResponse> => {
		return $http.get(
			`v1/staff/group_classes/${group_class_id}/group_monthly_reports/${monthly_report_id}/students_group_monthly_reports?student_id=${student_id}`
		);
	},

	createNoteForStudentGroup: (
		group_class_id: string,
		monthly_report_id: string,
		params: any
	): Promise<AxiosResponse> => {
		return $http.post(
			`v1/staff/group_classes/${group_class_id}/group_monthly_reports/${monthly_report_id}/students_group_monthly_reports`,
			params
		);
	},

	updateNoteForStudentGroup: (
		group_class_id: string,
		monthly_report_id: string,
		note_id: string,
		params: any
	): Promise<AxiosResponse> => {
		return $http.put(
			`v1/staff/group_classes/${group_class_id}/group_monthly_reports/${monthly_report_id}/students_group_monthly_reports/${note_id}`,
			params
		);
	},

	fetchStatsForStudentGroup: (
		group_class_id: string,
		monthly_report_id: string,
		student_id: string
	): Promise<AxiosResponse> => {
		return $http.get(
			`v1/staff/group_classes/${group_class_id}/group_monthly_reports/${monthly_report_id}/get_sessions_count_and_emotion_scale_stats_by_student?student_id=${student_id}`
		);
	},
	periodReports: (
		student_id: string | number,
		startDate: string | number,
		endDate: string | number
	): Promise<AxiosResponse> => {
		return $http.get(
			`/v1/staff/students/${student_id}/period_reports?start_date=${startDate}&end_date=${endDate}`
		);
	},
};
