/* eslint-disable */
import dayjs from 'dayjs';

export function _TO_WEEK(date: string, format_2 = false) {
	let dateCheck;
	if (!date || date.toLowerCase() === 'invalid date') {
		return null;
	}
	if (date) {
		if (format_2) {
			const data = date.split('-');
			dateCheck = new Date(+data[0], +data[1] - 1, +data[2]);
		} else {
			const data = date.split('/');
			dateCheck = new Date(+data[2], +data[1] - 1, +data[0]);
		}
	}
	if (!dateCheck) {
		return null;
	}
	let oneJan = new Date(dateCheck.getFullYear(), 0, 1);
	// @ts-ignore
	let numberOfDays = Math.floor((dateCheck - oneJan) / (24 * 60 * 60 * 1000));
	return Math.ceil((dateCheck.getDay() + 1 + numberOfDays) / 7);
}

export function _END_WEEK(date: string) {
	if (!date || date.toLowerCase() === 'invalid date') {
		return null;
	}
	const data = date.split('-');
	const dateCheck = new Date(+data[0], +data[1] - 1, +data[2]);
	return dayjs(dateCheck).add(7, 'day').format('DD/MM/YYYY');
}
