import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { _var } from '@/enums/Common';
import store from '@/store';

export const $http: AxiosInstance = axios.create({
	baseURL: process.env.VUE_APP_BASE_URL,
});

$http.defaults.headers.common['x-access-token'] = localStorage.getItem(
	_var.TOKEN
);
$http.defaults.headers.common['x-subdomain'] = localStorage.getItem(
	_var.SUBDOMAIN
);
$http.interceptors.request.use((req: any) => {
	req['x-access-token'] = localStorage.getItem(_var.TOKEN);
	req['x-subdomain'] = localStorage.getItem(_var.SUBDOMAIN);
	return req;
});
$http.interceptors.response.use(
	(config: AxiosResponse) => {
		return config;
	},
	(error) => {
		if (error.response.status === 401) {
			localStorage.clear();
			window.location.href = '/signin';
		}
		if (error.response.status === 500) {
			error.message = 'Đã có lỗi xảy ra. Vui lòng thử lại.';
		}
		if (error.response.status === 412) {
			const message =
				'Chúng tôi rất tiếc phải khóa tài khoản của quý cơ sở của trên Luca Education, do gói đăng ký của quý cơ sở hiện đã hết hạn hoặc chưa hoàn thành thanh toán. Vui lòng gia hạn gói hoặc hoành thành thanh toán để tiếp tục sử dụng.';
			return store.dispatch('setAlertMessageDontHide', {
				message: message,
				type: 'error',
			});
		}
		return Promise.reject(error);
	}
);
