/* eslint-disable */
import { $http } from '@/plugins/axios';
import { AxiosResponse } from 'axios';

export const studentService = {
	getStudents: (
		role: string = 'staff',
		params: any,
		oldData?: any
	): Promise<AxiosResponse> => {
		return $http.get(
			`v1/${role}/students`,
			oldData
				? {
						params,
						headers: {
							common: oldData,
						},
				  }
				: { params }
		);
	},
	getStudentsByDaycare: (
		id: number,
		params?: any
	): Promise<AxiosResponse> => {
		return $http.get(
			`v1/org_admin/day_care_classes/${id}/day_care_class_memberships/all`,
			{ params }
		);
	},
	createStudent: (data: any): Promise<AxiosResponse> => {
		return $http.post(`v1/org_admin/students`, data);
	},
	getStudent: (id: string, oldData?: any): Promise<AxiosResponse> => {
		return $http.get(
			`v1/staff/students/${id}`,
			oldData
				? {
						headers: {
							common: oldData,
						},
				  }
				: {}
		);
	},
	deleteStudent: (id: string): Promise<AxiosResponse> => {
		return $http.delete(`v1/org_admin/students/${id}`);
	},
	updateStudent: (id: string, data: any): Promise<AxiosResponse> => {
		return $http.put(`v1/org_admin/students/${id}`, data);
	},
	studentsForStaff: (id: string) => {
		return $http.get(`/v1/org_admin/staffs/${id}/students`);
	},
	openStudent: (id: string, state?: string) => {
		return $http.put(
			`/v1/org_admin/students/${id}/open`,
			{},
			{
				params: {
					state,
				},
			}
		);
	},
	closeStudent: (id: string, state: string) => {
		return $http.put(
			`/v1/org_admin/students/${id}/close`,
			{},
			{
				params: {
					state,
				},
			}
		);
	},
	daycareClasses: (id: string, isAdmin = true, oldData?: any) => {
		return $http.get(
			`/v1/${
				isAdmin ? 'org_admin' : 'staff'
			}/students/${id}/day_care_class_memberships`,
			oldData
				? {
						headers: {
							common: oldData,
						},
				  }
				: {}
		);
	},
	uploadProfilePicture(student_id: string, data: any) {
		return $http.put(
			`v1/staff/students/${student_id}/upload_profile_photo`,
			data,
			{
				headers: {
					'Content-Type':
						'multipart/form-data; boundary=profile_photo',
				},
			}
		);
	},
	uploadResultFile(
		student_id: number,
		assessment_document_id: number,
		data: any
	) {
		return $http.post(
			`/v1/staff/students/${student_id}/assessment_documents/${assessment_document_id}/files`,
			data,
			{
				headers: {
					'Content-Type':
						'multipart/form-data; boundary=plug_conn_test',
				},
			}
		);
	},
	deleteResultFile(
		student_id: number,
		assessment_document_id: number,
		file_id: number
	) {
		return $http.delete(
			`/v1/staff/students/${student_id}/assessment_documents/${assessment_document_id}/files/${file_id}`
		);
	},
	parents(student_id: string, oldData?: any) {
		return $http.get(
			`v1/org_admin/students/${student_id}/parent_invitations`,
			oldData
				? {
						headers: {
							common: oldData,
						},
				  }
				: {}
		);
	},
	sendInviteParent(
		student_id: string,
		email: string,
		ignore_parent_deleted: boolean
	) {
		return $http.post(
			`v1/org_admin/students/${student_id}/parent_invitations`,
			{
				parent_invitation: {
					email: email,
					ignore_parent_deleted: ignore_parent_deleted || false,
				},
			}
		);
	},
	reNewInviteParent(student_id: string, parent_id: string) {
		return $http.post(
			`v1/org_admin/students/${student_id}/parent_invitations/${parent_id}/code`
		);
	},
	sendEmailInviteParent(student_id: string, id: string) {
		return $http.post(
			`v1/org_admin/students/${student_id}/parent_invitations/${id}/invite`
		);
	},
	closeInviteParent(student_id: string, parent_id: string) {
		return $http.put(
			`v1/org_admin/students/${student_id}/parent_invitations/${parent_id}/close`
		);
	},
	assessmentDocuments(student_id: string | number, oldData?: any) {
		return $http.get(
			`v1/staff/students/${student_id}/assessment_documents`,
			oldData
				? {
						headers: {
							common: oldData,
						},
				  }
				: {}
		);
	},
	assessmentDocumentDetail(
		student_id: string | number,
		id: string | number,
		oldData?: any
	) {
		return $http.get(
			`v1/staff/students/${student_id}/assessment_documents/${id}`,
			oldData
				? {
						headers: {
							common: oldData,
						},
				  }
				: {}
		);
	},
	createAssessmentDocuments(student_id: string | number, data: any) {
		return $http.post(
			`v1/staff/students/${student_id}/assessment_documents`,
			data
		);
	},
	updateAssessmentDocuments(
		student_id: string | number,
		id: string | number,
		data: any
	) {
		return $http.put(
			`v1/staff/students/${student_id}/assessment_documents/${id}`,
			data
		);
	},
	deleteAssessmentDocuments(
		student_id: string | number,
		id: string | number
	) {
		return $http.delete(
			`v1/org_admin/students/${student_id}/assessment_documents/${id}`
		);
	},
	fetchDashboard: (
		id: string,
		params: any,
		oldData?: any
	): Promise<AxiosResponse> => {
		return $http.get(
			`v1/org_admin/students/${id}/stats`,
			oldData
				? {
						params,
						headers: {
							common: oldData,
						},
				  }
				: { params }
		);
	},
	fetchOrgTransferredHistory: (): Promise<AxiosResponse> => {
		return $http.get(`v1/org_admin/org_transfer_histories`);
	},

	fetchOrgTransferHistory: (
		id: string,
		oldData?: any
	): Promise<AxiosResponse> => {
		return $http.get(
			`v1/staff/students/${id}/org_transfer_histories`,
			oldData
				? {
						headers: {
							common: oldData,
						},
				  }
				: {}
		);
	},
	fetchOrgTransferHistoryOlCenter: (id: string): Promise<AxiosResponse> => {
		return $http.get(
			`v1/staff/students/${id}/org_transfer_histories/list_old_orgs`
		);
	},
	hideLabelOrgTransferHistory: (): Promise<AxiosResponse> => {
		return $http.put(`v1/staff/hide_toast_new_student`);
	},
	getStudentsInMedia: (
		role: string = 'staff',
		params: any
	): Promise<AxiosResponse> => {
		return $http.get(`v1/${role}/students/students_with_goal_media`, {
			params,
		});
	},
};
