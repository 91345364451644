/* eslint-disable */
import { $http } from '@/plugins/axios';
import { AxiosResponse } from 'axios';

export const weeklyReportService = {
	weeklyReports: (
		id: string,
		query: string = '',
		oldPayload?: any
	): Promise<AxiosResponse> => {
		return $http.get(
			`/v1/staff/students/${id}/weekly_reports${query}`,
			oldPayload
				? {
						headers: {
							common: oldPayload,
						},
				  }
				: {}
		);
	},
	weeklyReportsList: (query?: string): Promise<AxiosResponse> => {
		return $http.get(`/v1/staff/weekly_reports`, {
			params: query,
		});
	},
	weeklyReportsListOldData: (
		studentId: string,
		oldData: any,
		params?: any
	): Promise<AxiosResponse> => {
		return $http.get(
			`v1/staff/students/${studentId}/list_weekly_reports`,
			oldData
				? {
						params,
						headers: {
							common: oldData,
						},
				  }
				: { params }
		);
	},
	weeklyReportPublic: (query: string): Promise<AxiosResponse> => {
		return $http.get(`/v1/weekly_reports/show_by_token${query}`);
	},
	getToken(id: string, student_id: string) {
		return $http.post(
			`/v1/staff/students/${student_id}/weekly_reports/${id}/share`
		);
	},
	updateWeeklyReport: (
		id: string,
		weekly_report: { note: string; week_start: string }
	): Promise<AxiosResponse> => {
		return $http.post(`/v1/staff/students/${id}/weekly_reports`, {
			weekly_report,
		});
	},
};
