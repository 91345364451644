export const _ROUTER_NAME = {
	HOME: 'home',
	_COMPONENTS: 'components',
	LOGIN: 'login',
	REGISTER: 'register',
	INVALID_TOKEN: 'invalid_token',
	FORGOT_PASSWORD: 'forgot_password',
	RESET_PASSWORD: 'reset_password',
	CHOOSE_CENTER: 'choose_center',
	SETUP_CENTER: 'setup_center',
	SETUP_INFORMATION: 'setup_information',
	SETUP_TIMESHEET: 'setup_timesheet',
	SETUP_ROOM: 'setup_room',
	SETUP_BOARDING: 'setup_boarding',
	SETUP_SCHEDULE: 'setup_schedule',
	SETUP_TARGET_SMART: 'setup_target-smart',
	SETUP_TARGET_GOALS: 'setup_goals',
	SETUP_REPORT: 'setup_report',
	SETUP_PERIODIC_ASSESSMENT: 'setup_periodic_assessment',
	SETUP_PERIODIC_ASSESSMENT_ADD_STUDENT:
		'setup_periodic_assessment_add_student',
	SETUP_OTHERS: 'setup_others',
	SETUP_MEDIA: 'setup_media',
	RECORD: 'record',
	RECORD_EMPLOYEE: 'record_employee',
	RECORD_EMPLOYEE_DETAIL: 'record_employee_detail',
	RECORD_EMPLOYEE_DETAIL_INFO: 'record_employee_detail_info',
	RECORD_EMPLOYEE_STATS: 'record_employee_stats',
	RECORD_EMPLOYEE_STATS_DAYCARE: 'record_employee_stats_daycare',
	RECORD_STUDENT: 'record_student',
	RECORD_STUDENT_DETAIL: 'record_student_detail',
	RECORD_STUDENT_DETAIL_INFO: 'record_student_detail_info',
	RECORD_STUDENT_DETAIL_STAFF: 'record_student_detail_staff',
	RECORD_STUDENT_STATS: 'record_student_stats',
	RECORD_STUDENT_DETAIL_RESULT: 'record_student_detail_result',
	RECORD_STUDENT_DETAIL_RESULT_DETAIL: 'record_student_detail_result_detail',
	RECORD_STUDENT_DETAIL_PLAN: 'record_student_detail_plan',
	RECORD_STUDENT_DETAIL_TARGET: 'record_student_detail_target',
	RECORD_STUDENT_DETAIL_TARGET_CREATE: 'record_student_detail_target_create',
	RECORD_STUDENT_DETAIL_TARGET_STOP: 'record_student_detail_target_stop',
	RECORD_STUDENT_DETAIL_TARGET_COMPLETED:
		'record_student_detail_target_completed',
	GOAL_MANAGEMENT: 'goal_management',
	GOAL_MANAGEMENT_STUDENT_DETAIL: 'goal_management_student_detail',
	GOAL_MANAGEMENT_STUDENT_SUMMARY: 'goal_management_student_summary',
	GOAL_MANAGEMENT_STUDENT_SKILL_DETAILS:
		'goal_management_student_skill_details',
	GOAL_MANAGEMENT_STUDENT_GOAL_DETAILS:
		'goal_management_student_goal_details',
	STAFF_REGISTER: 'staff_register',
	REPORT: 'report',
	REPORT_LESSON: 'report_lesson',
	REPORT_DAY_CARE: 'report_day-care',
	REPORT_PLANS: 'report_monthly',
	REPORT_PLANS_CLASS: 'report_monthly_class',
	REPORT_PLANS_GROUP: 'report_monthly_group',
	REPORT_PLANS_GROUP_DETAIL: 'report_monthly_group_detail',
	REPORT_PLANS_GROUP_EDIT: 'report_monthly_group_edit',
	REPORT_MONTHLY: 'report_monthly_detail',
	REPORT_MONTHLY_GOAL: 'report_monthly_goal_detail',
	REPORT_WEEK: 'report_week',
	REPORT_GROUP: 'report_group',
	REPORT_GROUP_DETAIL: 'report_group_detail',
	REPORT_WEEK_DETAIL: 'report_week_detail',
	REPORT_WEEK_DETAIL_PUBLIC: 'report_week_detail_public',
	REPORT_MONTHLY_DETAIL_PUBLIC: 'report_monthly_detail_public',
	REPORT_LESSON_DETAIL_PUBLIC: 'report_lesson_detail_public',
	REPORT_STUDENT: 'report_lesson__student',
	REPORT_DAY_CARE_DETAIL: 'report_day-care__student',
	PLANS_REPORT: 'plans_report',
	PLAN_IN_HISTORY: 'plan_in_history',
	PLANS_REPORT_DETAIL: 'plans_report_detail',
	PLANS_REPORT_DETAIL__ALL: 'plans_report_detail__all',
	PLANS_REPORT_DETAIL__CURRENT: 'plans_report_detail__current',
	PLANS_REPORT_DETAIL__FUTURE: 'plans_report_detail__future',
	PLANS_REPORT_DETAIL__GOAL_BANK: 'plans_report_detail__goal_bank',
	PLANS_REPORT_DETAIL__HISTORY_PLAN: 'plans_report_detail__history_plan',
	PLANS_REPORT_DETAIL__GOAL_COMPLETED: 'plans_report_detail__goal_completed',
	PLANS_REPORT_DETAIL__GOAL_STOP: 'plans_report_detail__goal_stop',
	PLANS_REPORT_DETAIL__GOAL_CREATE: 'plans_report_detail__goal_create',
	PLANS_REPORT_DETAIL__GOAL_CREATE_FUTURE:
		'plans_report_detail__goal_create_future',
	PLANS_REPORT_DETAIL__GOAL_CREATE_FUTURE__CREATE:
		'plans_report_detail__goal_create_future__create',
	PLANS_REPORT_DETAIL__GOAL_CREATE_FUTURE__CREATE_BY_BANK:
		'plans_report_detail__goal_create_future__create_by_bank',
	PLANS_REPORT_DETAIL__GOAL_CREATE_FUTURE__CREATE_BAY_STOP:
		'plans_report_detail__goal_create_future__create_by_stop',
	PLANS_REPORT_DETAIL__GOAL_CREATE_FUTURE__CREATE_BAY_COMPLETED:
		'plans_report_detail__goal_create_future__create_by_completed',
	PLANS_REPORT_DETAIL__GOAL_CREATE_CURRENT:
		'plans_report_detail__goal_create_current',
	PLANS_REPORT_DETAIL__GOAL_CREATE_CURRENT__CREATE:
		'plans_report_detail__goal_create_current__create',
	PLANS_REPORT_DETAIL__GOAL_CREATE_CURRENT__CREATE_BY_BANK:
		'plans_report_detail__goal_create_current__create_by_bank',
	PLANS_REPORT_DETAIL__GOAL_CREATE_CURRENT__CREATE_BAY_STOP:
		'plans_report_detail__goal_create_current__create_by_stop',
	PLANS_REPORT_DETAIL__GOAL_CREATE_CURRENT__CREATE_BY_COMPLETED:
		'plans_report_detail__goal_create_current__create_by_completed',
	PLANS_REPORT_DETAIL__GOAL_CREATE_LIB:
		'plans_report_detail__goal_create_lib',
	PLANS_REPORT_DETAIL__GOAL_CREATE_LIB__CREATE:
		'plans_report_detail__goal_create_lib__create',
	CREATE_GOAL_BY_LIB: 'create_goal_by_lib',
	CREATE_GOAL_BY_LIB_GOALS: 'create_goal_by_lib_goals',
	CREATE_GOAL_BY_LIB_GOALS_TVMT: 'create_goal_by_lib_goals_tvmt',
	CREATE_GOAL_BY_LIB_GOALS_TVMT_FUTURE:
		'create_goal_by_lib_goals_tvmt_future',
	GOAL: 'goal',
	GOAL_IN_GROUP: 'goal_in_group',
	DAYCARE_CLASS: 'daycare_class',
	DAYCARE_CLASS_DETAIL: 'daycare_class_detail',
	NOTIFICATION: 'notifications',
	NOTIFICATION_CREATE: 'notifications_create',
	NOTIFICATION_DETAIL: 'notifications_detail',
	FEE_NOTICES: 'fee_notices',
	FEE_NOTICES_CREATE: 'fee_notices_create',
	FEE_NOTICES_EDIT: 'fee_notices_edit',
	FEE_NOTICES_DETAIL: 'fee_notices_detail',
	FEE_NOTICES_MARK_PAID: 'fee_notices_detail_mark_paid',
	SCHEDULE_TIMETABLE: 'schedule_timetable',
	SCHEDULE_TIMETABLE_CLASS: 'schedule_timetable_class',
	SCHEDULE_TIMETABLE_GROUP_CLASSES: 'schedule_timetable_group_class',
	SCHEDULE_TIMETABLE_HISTORY: 'schedule_timetable_history',
	SCHEDULE_TIMESLOT: 'schedule_timeslot',
	SCHEDULE_STATS: 'schedule_stats',
	SCHEDULE_STATS_DETAIL: 'schedule_stats_detail',
	SCHEDULE_STATS_DETAIL__CLASS: 'schedule_stats_detail__class',
	SCHEDULE_STATS_DETAIL__DAYCARE: 'schedule_stats_detail__daycare',
	SCHEDULE_STATS_DETAIL__GROUP_CLASS: 'schedule_stats_detail__group',
	SCHEDULE_TIMESLOT__CLASS: 'schedule_timeslot__class',
	SCHEDULE_TIMESLOT__DAYCARE: 'schedule_timeslot__daycare',
	SCHEDULE_TIMESLOT__GROUP_CLASSES: 'schedule_timeslot__group',
	PACKAGE: 'package',
	GOAL_LIB: 'goal_lib',
	GOAL_FOLDERS: 'goal_lib_folders',
	GOAL_REFERENCE_FOLDERS: 'goal_reference_folders',
	GOAL_REFERENCE: 'goal_reference',
	DASHBOARD: 'dashboard',
	DASHBOARD_ORG: 'dashboard_org',
	HOLIDAY: 'holiday',
	BIRTHDAY: 'birthdays',
	SESSION_MANAGEMENT: 'section_management',
	SESSION_MANAGEMENT__CLASS: 'section_management__class',
	SESSION_MANAGEMENT__DAYCARE: 'section_management__daycare',
	SESSION_MANAGEMENT__GROUP_CLASS: 'section_management__group_class',
	MEDIA_HISTORY: 'media_history',
	ADD_STUDENTS: 'add_students',
	TIMETABLE_STUDENTS: 'timetable_students',
	NOT_FOUND: 'not_found',
	TRANSFERRED_DATA: 'transferred_data',
	OLD_STUDENT_DATA: 'old_student_data',
	OLD_STUDENT_DATA_LESSON: 'old_student_data_lesson',
	OLD_STUDENT_DATA_DAYCARE: 'old_student_data_daycare',
	OLD_STUDENT_DATA_WEEK: 'old_student_data_week',
	OLD_STUDENT_DATA_PLAN: 'old_student_data_plan',
	PROFILE: 'profile',
	CREATE_GROUP: 'create_group',
	GROUP_CLASSES: 'group_classes',
	GROUP_CLASSES_DETAIL: 'group_classes_detail',
	GROUP_CLASSES_ADD_STUDENT: 'group_classes_add_student',
	GROUP_CLASSES_ADD_STAFF: 'group_classes_add_staff',
	GROUP_CLASS_PLANS: 'group_class_plans',
	GROUP_CLASS_PLANS_DETAIL: 'group_class_plans_detail',
	GROUP_CLASS_PLANS_DETAIL_REPORT: 'group_class_plans_detail_report',
	GROUP_CLASS_PLANS_DETAIL_REPORT_FUTURE:
		'group_class_plans_detail_report_future',
	GROUP_CLASS_PLANS_DETAIL_REPORT_DETAIL:
		'group_class_plans_detail_report_detail',
	GROUP_CLASS_PLANS_DETAIL_REPORT_CREATE:
		'group_class_plans_detail_report_create',
	GROUP_CLASS_PLANS_DETAIL_REPORT_HISTORY:
		'group_class_plans_detail_report_history ',
	GROUP_CLASS_PLANS_DETAIL_REPORT_BANK:
		'group_class_plans_detail_report_bank',
	GROUP_CLASS_PLANS_DETAIL_GOALS_COMPLETED:
		'group_class_plans_detail_goals_completed',
	GROUP_CLASS_PLANS_DETAIL_GOALS_STOPPED:
		'group_class_plans_detail_goals_stopped',
	GROUP_CLASS_PLANS_DETAIL_GOALS_ALL: 'group_class_plans_detail_goals_all',
	GROUP_CLASS_PLANS_DETAIL_CREATE: 'group_class_plans_detail_create',
	GROUP_CLASS_PLANS_DETAIL_CREATE_BY_NEW_GOAL:
		'group_class_plans_detail_create_new_goal',
	GROUP_CLASS_PLANS_DETAIL_CREATE_BY_NEW_GOAL_FROM_STOPPED:
		'group_class_plans_detail_create_new_goal_from_stopped',
	GROUP_CLASS_PLANS_DETAIL_CREATE_BY_NEW_GOAL_FROM_COMPLETED:
		'group_class_plans_detail_create_new_goal_from_completed',
	GROUP_CLASS_PLANS_DETAIL_CREATE_BY_NEW_GOAL_FROM_LIB:
		'group_class_plans_detail_create_new_goal_from_lib',
	GROUP_CLASS_PLANS_DETAIL_CREATE_BY_NEW_GOAL_FROM_TVMT:
		'group_class_plans_detail_create_new_goal_from_tvmt',
	GROUP_CLASS_PLANS_DETAIL_CREATE_FUTURE:
		'group_class_plans_detail_create_future',
	GROUP_CLASS_PLANS_DETAIL_CREATE_BY_NEW_GOAL_FUTURE:
		'group_class_plans_detail_create_new_goal_future',
	GROUP_CLASS_PLANS_DETAIL_CREATE_BY_NEW_GOAL_FROM_LIB_FUTURE:
		'group_class_plans_detail_create_new_goal_from_lib_future',
	GROUP_CLASS_PLANS_DETAIL_CREATE_BY_NEW_GOAL_FROM_TVMT_FUTURE:
		'group_class_plans_detail_create_new_goal_from_tvmt_future',
	DOCUMENTS: 'documents',
};
